import { indexConstants } from '../_constants';
import { indexService } from '../_services';

export const indexActions = {
    'getAll': getAll,
    sendMail
};

function getAll() {
    return dispatch => {
        dispatch(request());

        return indexService.loadAll()
            .then(
                news => {
                    dispatch(success(news.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(language) { return { type: indexConstants.LOAD_ALL_REQUEST } }
    function success(news) { return { type: indexConstants.LOAD_ALL_SUCCESS, news } }
    function failure(id, error) { return { type: indexConstants.LOAD_ALL_FAILURE, error } }
}

function sendMail(mail) {
    return dispatch => {
        dispatch(request(mail));

        return indexService.sendMail(mail)
            .then(
                mail => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(mail) { return { type: indexConstants.SEND_MAIL_REQUEST, mail } }
    function success() { return { type: indexConstants.SEND_MAIL_SUCCESS } }
    function failure(id, error) { return { type: indexConstants.SEND_MAIL_FAILURE, error } }
}