import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import fileDownload from 'js-file-download';

export const recommendationService = {
    get,
    downloadReport,
    sendByEmail,
    generateBatchReports,
    downloadReports
};

function get(patient_id, pcc=false, site_id=0) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/recommendations/'+patient_id+'/'+site_id+'/1/1', requestOptions);
    
}

function sendByEmail(patient_id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/recommendations/email/'+patient_id, requestOptions);
}

function downloadReport(id, language) {
    const requestOptions = {
        headers: authHeader(),
        responseType: 'blob'
    };

    return axios.get('/report_' + id + '_'+language+'.pdf', requestOptions).then(data => {fileDownload(data.data, 'report.pdf');});
}

function generateBatchReports(site) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.post('/api/recommendations/generate_batch_reports/'+site, null, requestOptions);
}

function downloadReports(site_id) {
    const requestOptions = {
        headers: authHeader(),
        responseType: 'blob'
    };

    return axios.get('/api/recommendations/generate_batch_reports/'+site_id, requestOptions).then(data => {fileDownload(data.data, 'reports_site_'+site_id+'.zip');});
}
