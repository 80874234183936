import axios from "axios";
import { authHeader, handleResponse } from '../_helpers';

export const adminService = {
  downloadDPDFiles,
  getDrugExceptions,
  update,
  updateSite
};



function downloadDPDFiles() {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get("/download_dpd", config).then(
    response => {
      return response;
    });
}

function getDrugExceptions() {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get("/get_drug_exceptions", config).then(
    response => {
      return response;
    });
}

function update(cellEdit) {
  const config = {
    headers: { ...authHeader() }
  };

  return axios.post('/exception', cellEdit, config).then(handleResponse);
}

function updateSite(cellEdit) {
  const config = {
    headers: { ...authHeader() }
  };

  return axios.post('/pcc/site', cellEdit, config).then(handleResponse);
}