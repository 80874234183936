import { subscriptionConstants } from '../_constants';

export function subscription(state = {}, action) {
  switch (action.type) {
    case subscriptionConstants.SUBSCRIBE_SUCCESS:
      return { subscription: action.result && action.result.data &&  action.result.data.subscription };
    case subscriptionConstants.LOAD_SUCCESS:
      return { subscription: action.result && action.result.data &&  action.result.data };
    case subscriptionConstants.PORTAL_SUCCESS:
      return { redirect: action.result };
    case subscriptionConstants.CLEAR_PORTAL:
        return { redirect: null };
    default:
      return state
  }
}
