import { conditionConstants } from '../_constants';
import { conditionService } from '../_services';

export const conditionActions = {
    updateSearch,
    'save': save,
    savePublic,
    loadQuestions,
    nextQuestion,
    previousQuestion,
    resetQuestion
};

function savePublic(values, patient_id) {
    return dispatch => {
        var formatted_values = []
        for (const [condition_code, condition_value] of Object.entries(values)) {
            if (condition_code === "egfr") {
                if (condition_value === "Yes") {
                    formatted_values.push({condition_code:condition_code, condition_value:"34"})
                } else {
                    formatted_values.push({condition_code:condition_code, condition_value:"36"})
                }
                
            } else if (condition_code === "stent_type") {
                var timing = values['stent_timing']

                if (condition_value === "BMS") {
                    formatted_values.push({condition_code:"myocard_infarction_stented_bms", condition_value:timing})
                } else if (condition_value === "DES") {
                    formatted_values.push({condition_code:"myocard_infarction_stented_des", condition_value:timing})
                } else {
                    formatted_values.push({condition_code:"myocard_infarction_stented_unk", condition_value:timing})
                }
                
            } else if (condition_code === "diabetes_hemoglobin_alc_perc") {
                if (condition_value === "Yes") {
                    formatted_values.push({condition_code:condition_code, condition_value:"7.4"})
                } else {
                    formatted_values.push({condition_code:condition_code, condition_value:"7.6"})
                }
                
            } else {
                formatted_values.push({condition_code:condition_code, condition_value:condition_value})
            }
            
        }
        dispatch(request(formatted_values, patient_id));
        return conditionService.save(formatted_values, patient_id)
            .then(
                values => {
                    dispatch(success(values.data, patient_id));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(values) { return { type: conditionConstants.SAVE_REQUEST, values, patient_id } }
    function success(response) { return { type: conditionConstants.SAVE_SUCCESS, response } }
    function failure(id, error) { return { type: conditionConstants.SAVE_FAILURE, id, error } }

}

function save(values, patient_id) {
    return dispatch => {
        dispatch(request(values, patient_id));
        return conditionService.save(values, patient_id)
            .then(
                values => {
                    dispatch(success(values.data, patient_id));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(values) { return { type: conditionConstants.SAVE_REQUEST, values, patient_id } }
    function success(response) { return { type: conditionConstants.SAVE_SUCCESS, response } }
    function failure(id, error) { return { type: conditionConstants.SAVE_FAILURE, id, error } }

}

function updateSearch(searchQuery) {
    return { type: conditionConstants.UPDATE_SEARCH, searchQuery }
}

function loadQuestions(patient_id)  {
    return dispatch => {
        dispatch(request(patient_id));

        return conditionService.loadQuestions(patient_id)
            .then(
                questions => {
                    dispatch(success(questions.data.sort((a, b)=>{return a.group-b.group})));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(patient_id) { return { type: conditionConstants.LOAD_QUESTIONS_REQUEST, patient_id } }
    function success(questions) { return { type: conditionConstants.LOAD_QUESTIONS_SUCCESS, questions } }
    function failure(id, error) { return { type: conditionConstants.LOAD_QUESTIONS_FAILURE, id, error } }
}

function nextQuestion() {
    return { type: conditionConstants.NEXT_QUESTION };
}

function previousQuestion() {
    return { type: conditionConstants.PREVIOUS_QUESTION };
}

function resetQuestion() {
    return { type: conditionConstants.RESET_QUESTION };
}