export const conditionConstants = {
    UPDATE_SEARCH: 'CONDITION_UPDATE_SEARCH',
    NEXT_QUESTION: 'CONDITION_NEXT_QUESTION',
    PREVIOUS_QUESTION: 'CONDITION_PREVIOUS_QUESTION',
    SAVE_REQUEST: 'CONDITION_SAVE_REQUEST',
    SAVE_SUCCESS: 'CONDITION_SAVE_SUCCESS',
    SAVE_FAILURE: 'CONDITION_SAVE_FAILURE',
    LOAD_QUESTIONS_REQUEST: 'CONDITION_LOAD_QUESTIONS_REQUEST',
    LOAD_QUESTIONS_SUCCESS: 'CONDITION_LOAD_QUESTIONS_SUCCESS',
    LOAD_QUESTIONS_FAILURE: 'CONDITION_LOAD_QUESTIONS_FAILURE',
    RESET_QUESTION: 'CONDITION_RESET_QUESTION',
};
