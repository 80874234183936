import axios from "axios";

export const indexService = {
  loadAll,
  sendMail,
};

function loadAll() {
  return axios.get("/api/news").then(
    response => {
      return response;
    });
}

function sendMail(mail) {
  return axios.post("/api/contact", mail).then(
    response => {
      return response;
    });
}