export const adminConstants = {
    DOWNDLOAD_DPD_REQUEST: 'ADMIN_DOWNDLOAD_DPD_REQUEST',
    DOWNDLOAD_DPD_SUCCESS: 'ADMIN_DOWNDLOAD_DPD_SUCCESS',
    DOWNDLOAD_DPD_FAILURE: 'ADMIN_DOWNDLOAD_DPD_FAILURE',
    GET_DRUGS_REQUEST: 'ADMIN_GET_DRUGS_REQUEST',
    GET_DRUGS_SUCCESS: 'ADMIN_GET_DRUGS_SUCCESS',
    GET_DRUGS_FAILURE: 'ADMIN_GET_DRUGS_FAILURE',
    SAVE_REQUEST: 'ADMIN_SAVE_REQUEST',
    SAVE_SUCCESS: 'ADMIN_SAVE_SUCCESS',
    SAVE_FAILURE: 'ADMIN_SAVE_FAILURE',
    UPDATE_SITE_REQUEST: 'ADMIN_UPDATE_SITE_REQUEST',
    UPDATE_SITE_SUCCESS: 'ADMIN_UPDATE_SITE_SUCCESS',
    UPDATE_SITE_FAILURE: 'ADMIN_UPDATE_SITE_FAILURE'
};
