import { authHeader, handleResponse } from '../_helpers';
import { store } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';

export const medicationService = {
    search,
    searchPublic,
    update,
    get,
    put,
    delete: _delete,
    loadQuestions,
};

function loadQuestions(patient_id) {

    const config = {
      headers: { ...authHeader() }
    };
    return axios.get('/api/medication/questions/' + patient_id, config).then(handleResponse);
}


function search(query, patient_id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/search/medications/'+query, requestOptions).then(handleResponse);
}

function searchPublic(query) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/search/public/medications/'+query, requestOptions).then(handleResponse);
}

function update(patient_id, cellEdit) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/api/medication', {...cellEdit, patient_id:patient_id.toString()}, config).then(handleResponse).catch(error => {

      store.dispatch(toastError(JSON.stringify(error.message)));
    });
}

function put(patient, medication, isOutcome) {
    const config = {
      headers: { ...authHeader() }
    };
    console.log("PUT MED");
    console.log(patient)
    return axios.put('/api/medication', {"internal_id":patient.internal_id, "din":medication.din, "sentence_id":parseInt(medication.sentence_id), "frequency":"daily", "outcome_medication": isOutcome}, config).then(handleResponse);
}

function get(patient) {
    const config = {
      headers: { ...authHeader() }
    };
    if ('site_id' in patient) {
      return axios.get('/api/medications/'+patient.patient_id+'/'+patient.site_id, config).then(handleResponse);
    } else {
      return axios.get('/api/medications/'+patient.internal_id, config).then(handleResponse);
    }

}

function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/api/medication/' + id, requestOptions).then(handleResponse).catch(error => {
      store.dispatch(toastError(JSON.stringify(error.message)));
    });
}
