import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { history } from '../_helpers';
import { store } from '../_helpers';

export const patientService = {
    getAll,
    getByPage,
    getCount,
    get,
    update,
    create,
    reopen,
    delete: _delete,
    syncPatientsPCC,
    syncPatientPCC
};

function getAll() {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.get('/api/search_patients', config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load patients. Please try again later."));});
}

function syncPatientPCC(id) {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get('/pcc/refresh_patient/' + id, config).then(handleResponse);
}


function syncPatientsPCC(site_id) {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get('/pcc/site/'+site_id, config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load patients. Please try again later."));});
}

function getByPage(page, pageSize, query, sites) {

    const config = {
      headers: { ...authHeader() },
      params: { sites: sites }
    };

    return axios.get('/api/search_patients/'+page+"/"+pageSize+"/"+query, config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load patients. Please try again later."));});
}

function getCount() {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get('/api/reports_count', config).then(handleResponse);
}

function reopen(id) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/api/reopen/' + id, {}, config).then(handleResponse);
}

function get(id, site=null) {
    const requestOptions = {
        headers: authHeader()
    };
    if (site) {
      return axios.get('/api/patient/' + id +'/'+ site, requestOptions).then(handleResponse);
    } else {
      return axios.get('/api/patient/' + id, requestOptions).then(handleResponse);
    }
    
}

function update(values) {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/api/patient', values, config).then(handleResponse);
}

function create(values) {
    const config = {
      headers: { ...authHeader() }
    };
    return axios.put('/api/patient', values, config).then(data=>{history.push("/main_form/"+data.data.internal_id);});
}

function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/api/patient/' + id, requestOptions).then(handleResponse);
}
