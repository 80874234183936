import { authHeader, refreshHeader, handleResponse } from '../_helpers';
import { userActions } from '../_actions';

import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store } from '../_helpers';
import { updateSession } from 'redux-simple-auth'

export const userService = {
    login,
    logout,
    register,
    getById,
    update,
    forgotPassword,
    resetPassword,
    changePassword,
    fetchJWTToken,
    verifyEmail,
    registerPublic,
    sendVerificationCode,
    resendValidationCode,
    reloadLocations,
    PCCAuth
};

function logout() {
    const requestOptions = {
        headers: refreshHeader()
    };
    return axios.post('/logout', null, requestOptions)
}

function login(data) {

    return axios.post('/login', data).then(handleResponse).catch(error => {

      if (!error.response || !error.response.status) {
        store.dispatch(toastError("Network Error. Make sure your internet connection is working."));
      }
      else if (error.response.status === 400 || error.response.status === 401) {
          if (error.response.data.error === "This user is not active") {
            store.dispatch(userActions.displayValidationRequest());
            return Promise.reject("You need to validate your account. Please click on Resend Validation Email if you didn't receive the validation email.");
        }
          else {
            return Promise.reject("Login failed. Make sure your email and password are entered correctly.");
          }
      }
      else {
        return Promise.reject("Something went wrong. Please try again later.");
      }
    });
}

function fetchJWTToken(refresh_token) {

      const requestOptions = {
          headers: refreshHeader()
      };

      return axios.post('/refresh', null, requestOptions)
        .then(data => {

            var user = localStorage.getItem('user');
            user = JSON.parse(user);

            if (user.user)
            {
              user = user.user
            }

            user.authentication_token = data.data.access_token;
            store.dispatch(updateSession({ user: user }));
            localStorage.setItem('user', JSON.stringify(user));
            return data.data.authentication_token;
      });
}

function getById(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/api/users/' + id, requestOptions).then(handleResponse);
}

function register(user) {
    return axios.post('/register', user).then(handleResponse);
}

function reloadLocations() {

    const requestOptions = {
        headers: authHeader()
    };

    return axios.get('/reload_locations', requestOptions).then(handleResponse);
}

function registerPublic(user) {
    return axios.post('/public/register', user).then(handleResponse);
}

function forgotPassword(user) {
    return axios.post('/forgot_password', user).then(handleResponse);
}

function resendValidationCode(email) {
    return axios.post('/resend_email_validation', {email:email}).then(handleResponse);
}

function sendVerificationCode(user) {
    return axios.post('/public/send_verification_code', user).then(handleResponse);
}

function verifyEmail(emailVerificationCode) {
    return axios.post('/validate_email', {email_confirmation_code:emailVerificationCode}).then(handleResponse);
}

function PCCAuth(code) {
    return axios.post('/pcc/auth', {code:code})
}

function changePassword(user) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.post('/change_password', user, requestOptions).then(handleResponse);
}


function resetPassword(user) {
    return axios.post('/reset_password', user).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        headers: { ...authHeader() },
        body: user
    };

    return axios.put('/api/users/' + user.id, requestOptions).then(handleResponse).catch(error => {store.dispatch(toastError(error.response))});
}