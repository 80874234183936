export const medicationConstants = {
    GETALL_REQUEST: 'MEDICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'MEDICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'MEDICATIONS_GETALL_FAILURE',
    CREATE_REQUEST: 'MEDICATIONS_CREATE_REQUEST',
    CREATE_SUCCESS: 'MEDICATIONS_CREATE_SUCCESS',
    CREATE_FAILURE: 'MEDICATIONS_CREATE_FAILURE',
    SAVE_REQUEST: 'MEDICATIONS_SAVE_REQUEST',
    SAVE_SUCCESS: 'MEDICATIONS_SAVE_SUCCESS',
    SAVE_FAILURE: 'MEDICATIONS_SAVE_FAILURE',
    DELETE_REQUEST: 'MEDICATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'MEDICATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'MEDICATIONS_DELETE_FAILURE',
    LOAD_QUESTIONS_REQUEST: 'MEDICATION_LOAD_QUESTIONS_REQUEST',
    LOAD_QUESTIONS_SUCCESS: 'MEDICATION_LOAD_QUESTIONS_SUCCESS',
    LOAD_QUESTIONS_FAILURE: 'MEDICATION_LOAD_QUESTIONS_FAILURE',
    NEXT_QUESTION: 'MEDICATION_NEXT_QUESTION',
    PREVIOUS_QUESTION: 'MEDICATION_PREVIOUS_QUESTION',
    RESET_QUESTION: 'MEDICATION_RESET_QUESTION',
};
