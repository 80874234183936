export const patientConstants = {
    GETALL_REQUEST: 'PATIENTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'PATIENTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PATIENTS_GETALL_FAILURE',
    GET_REQUEST: 'PATIENT_GET_REQUEST',
    GET_SUCCESS: 'PATIENT_GET_SUCCESS',
    GET_FAILURE: 'PATIENT_GET_FAILURE',
    GET_COUNT_REQUEST: 'PATIENT_GET_COUNT',
    GET_COUNT_SUCCESS: 'PATIENT_GET_COUNT',
    GET_COUNT_FAILURE: 'PATIENT_GET_COUNT',
    DELETE_REQUEST: 'PATIENT_DELETE_REQUEST',
    DELETE_SUCCESS: 'PATIENT_DELETE_SUCCESS',
    DELETE_FAILURE: 'PATIENT_DELETE_FAILURE',
    CREATE_REQUEST: 'PATIENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'PATIENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'PATIENT_CREATE_FAILURE',
    UPDATE_REQUEST: 'PATIENT_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PATIENT_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PATIENT_UPDATE_FAILURE',
    SEARCH_ARCHIVED_REQUEST: 'PATIENT_SEARCH_ARCHIVED_REQUEST',
    SEARCH_ARCHIVED_SUCCESS: 'PATIENT_SEARCH_ARCHIVED_SUCCESS',
    SEARCH_ARCHIVED_FAILURE: 'PATIENT_SEARCH_ARCHIVED_FAILURE',
    REOPEN_REQUEST: 'PATIENT_REOPEN_REQUEST',
    REOPEN_SUCCESS: 'PATIENT_REOPEN_SUCCESS',
    REOPEN_FAILURE: 'PATIENT_REOPEN_FAILURE',
    SELECT: 'PATIENT_SELECT',
    UPDATE_SEARCH: 'PATIENT_UPDATE_SEARCH',
    UPDATE_PAGINATION: 'PATIENT_UPDATE_PAGINATION',
    SYNC_REQUEST: 'PATIENT_SYNC_REQUEST',
    SYNC_SUCCESS: 'PATIENT_SYNC_SUCCESS',
    SYNC_FAILURE: 'PATIENT_SYNC_FAILURE',
};
