import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";

export const conditionService = {
    save,
    loadQuestions,
};

function save(values, patient_id) {

  const config = {
    headers: { ...authHeader() }
  };
  return axios.post('/api/conditions/' + patient_id, {"conditions":values}, config).then(handleResponse);
}


function loadQuestions(patient_id) {

    const config = {
      headers: { ...authHeader() }
    };
    return axios.get('/api/condition/questions/' + patient_id, config).then(handleResponse);
}
