import { recommendationConstants } from '../_constants';
import { recommendationService } from '../_services';
import { error as toastError } from 'react-toastify-redux';

export const recommendationActions = {
    'get': get,
    downloadReport,
    sendByEmail,
    generateBatchReports,
    downloadReports
};

function get(patientID, pcc=false, site_id=0) {
    return dispatch => {
        dispatch(request());

        return recommendationService.get(patientID, pcc, site_id)
            .then(
                (recommendations) => { 
                    dispatch(success(recommendations.data));
                    console.log(recommendations);
                }
//                error => {
//                    dispatch(failure(error));
//                }
            );
    };

    function request() { return { type: recommendationConstants.GETALL_REQUEST } }
    function success(recommendations) { return { type: recommendationConstants.GETALL_SUCCESS, recommendations } }
    function failure(error) { return { type: recommendationConstants.GETALL_FAILURE, error } }
}

function sendByEmail(patientID) {
    return dispatch => {
        dispatch(request());

        return recommendationService.sendByEmail(patientID)
            .then(
                recommendations => dispatch(success()),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: recommendationConstants.SEND_BY_EMAIL_REQUEST } }
    function success() { return { type: recommendationConstants.SEND_BY_EMAIL_SUCCESS } }
    function failure(error) { return { type: recommendationConstants.SEND_BY_EMAIL_FAILURE, error } }
}

function downloadReport(patientID, language) {
    return dispatch => {
        dispatch(request());

        return recommendationService.downloadReport(patientID, language)
            .then(
                report => dispatch(success()),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: recommendationConstants.DOWNLOAD_REPORT_REQUEST } }
    function success(report) { return { type: recommendationConstants.DOWNLOAD_REPORT_SUCCESS, report } }
    function failure(error) { return { type: recommendationConstants.DOWNLOAD_REPORT_FAILURE, error } }
}

function generateBatchReports(site) {
    return dispatch => {
        dispatch(request());

        return recommendationService.generateBatchReports(site)
            .then(
                reports => dispatch(success()),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: recommendationConstants.GENERATE_BATCH_REPORT_REQUEST } }
    function success(reports) { return { type: recommendationConstants.GENERATE_BATCH_REPORT_SUCCESS, reports } }
    function failure(error) { return { type: recommendationConstants.GENERATE_BATCH_REPORT_FAILURE, error } }
}

function downloadReports(site) {
    return dispatch => {
        dispatch(request());

        return recommendationService.downloadReports(site)
            .then(
                report => dispatch(success()),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: recommendationConstants.DOWNLOAD_REPORT_REQUEST } }
    function success(reports) { return { type: recommendationConstants.DOWNLOAD_REPORT_SUCCESS, reports } }
    function failure(error) { return { type: recommendationConstants.DOWNLOAD_REPORT_FAILURE, error } }
}
