export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    REGISTER_PUBLIC_REQUEST: 'USERS_REGISTER_PUBLIC_REQUEST',
    REGISTER_PUBLIC_SUCCESS: 'USERS_REGISTER_PUBLIC_SUCCESS',
    REGISTER_PUBLIC_FAILURE: 'USERS_REGISTER_PUBLIC_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    PCC_AUTH_REQUEST: 'USERS_PCC_AUTH_REQUEST',
    PCC_AUTH_SUCCESS: 'USERS_PCC_AUTH_SUCCESS',
    PCC_AUTH_FAILURE: 'USERS_PCC_AUTH_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'USERS_FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'USERS_FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'USERS_FORGOT_PASSWORD_FAILURE',

    SEND_VERIFICATION_CODE_REQUEST: 'USERS_SEND_VERIFICATION_CODE_REQUEST',
    SEND_VERIFICATION_CODE_SUCCESS: 'USERS_SEND_VERIFICATION_CODE_SUCCESS',
    SEND_VERIFICATION_CODE_FAILURE: 'USERS_SEND_VERIFICATION_CODE_FAILURE',

    RESET_PASSWORD_REQUEST: 'USERS_RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    CHANGE_PASSWORD_REQUEST: 'USERS_CHANGE_PASSWORD_REQUEST',
    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    VERIFY_EMAIL_REQUEST: 'USERS_VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'USERS_VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_FAILURE: 'USERS_VERIFY_EMAIL_FAILURE',

    RELOAD_LOCATIONS_REQUEST: 'USERS_RELOAD_LOCATIONS_REQUEST',
    RELOAD_LOCATIONS_SUCCESS: 'USERS_RELOAD_LOCATIONS_SUCCESS',
    RELOAD_LOCATIONS_FAILURE: 'USERS_RELOAD_LOCATIONS_FAILURE',

    REFRESHING_TOKEN: 'USERS_REFRESHING_TOKEN',
    DONE_REFRESHING_TOKEN: 'USERS_DONE_REFRESHING_TOKEN',

    CHANGE_LANGUAGE: 'USERS_CHANGE_LANGUAGE',

    TOGGLE_ARCHIVED: 'USERS_TOGGLE_ARCHIVED',
    TOGGLE_SITE: 'USERS_TOGGLE_SITE',

    DISPLAY_VALIDATION_REQUEST: "USERS_DISPLAY_VALIDATION_REQUEST",
    RESEND_VALIDATION_CODE_REQUEST: "USERS_REQUEST_VALIDATION_CODE_REQUEST",
    RESEND_VALIDATION_CODE_SUCCESS: "USERS_REQUEST_VALIDATION_CODE_SUCCESS",
    RESEND_VALIDATION_CODE_FAILURE: "USERS_REQUEST_VALIDATION_CODE_FAILURE",
};
