import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from '../_reducers';
import { jwt } from './jwt'
import { authMiddleware } from './auth-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar'

import {
  getInitialAuthState,
  storage,
} from 'redux-simple-auth'


const loggerMiddleware = createLogger();

const middlewares = [
  authMiddleware,
  jwt,
  thunkMiddleware,
  loadingBarMiddleware({
    promiseTypeSuffixes: ['REQUEST', 'SUCCESS', 'FAILURE'],
  }),
  process.env.NODE_ENV === 'development' && loggerMiddleware,
].filter(Boolean);

const middleware = applyMiddleware(...middlewares)

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancers(middleware, getInitialAuthState({ storage }))
);
