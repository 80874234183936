import { adminConstants } from '../_constants';
import { adminService } from '../_services';

export const adminActions = {
    downloadDPDFiles,
    getDrugExceptions,
    'update': update,
    updateSite
};

function downloadDPDFiles() {
    return dispatch => {
        dispatch(request());

        return adminService.downloadDPDFiles()
            .then(
                news => {
                    dispatch(success());
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminConstants.DOWNDLOAD_DPD_REQUEST } }
    function success() { return { type: adminConstants.DOWNDLOAD_DPD_SUCCESS } }
    function failure(id, error) { return { type: adminConstants.DOWNDLOAD_DPD_FAILURE, error } }
}

function getDrugExceptions() {
    return dispatch => {
        dispatch(request());

        return adminService.getDrugExceptions()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminConstants.GET_DRUGS_REQUEST } }
    function success(drugs) { return { type: adminConstants.GET_DRUGS_SUCCESS, drugs } }
    function failure(error) { return { type: adminConstants.GET_DRUGS_FAILURE, error } }
}

function update(cellEdit) {
    return dispatch => {
        dispatch(request());
        return adminService.update(cellEdit)
            .then(
                response => dispatch(success(cellEdit)),
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminConstants.SAVE_REQUEST } }
    function success(cellEdit) { return { type: adminConstants.SAVE_SUCCESS, cellEdit  } }
    function failure(error) { return { type: adminConstants.SAVE_FAILURE, error } }
}

function updateSite(cellEdit) {
    return dispatch => {
        dispatch(request());
        return adminService.updateSite(cellEdit)
            .then(
                response => dispatch(success(cellEdit)),
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: adminConstants.UPDATE_SITE_REQUEST } }
    function success(cellEdit) { return { type: adminConstants.UPDATE_SITE_SUCCESS, cellEdit  } }
    function failure(error) { return { type: adminConstants.UPDATE_SITE_FAILURE, error } }
}