export const favoriteMedicationsConstants = {
    GETALL_REQUEST: 'FAVORITE_MEDICATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'FAVORITE_MEDICATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'FAVORITE_MEDICATIONS_GETALL_FAILURE',
    ADD_REQUEST: 'FAVORITE_MEDICATIONS_ADD_REQUEST',
    ADD_SUCCESS: 'FAVORITE_MEDICATIONS_ADD_SUCCESS',
    ADD_FAILURE: 'FAVORITE_MEDICATIONS_ADD_FAILURE',
    DELETE_REQUEST: 'FAVORITE_MEDICATIONS_DELETE_REQUEST',
    DELETE_SUCCESS: 'FAVORITE_MEDICATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'FAVORITE_MEDICATIONS_DELETE_FAILURE',
    SWITCH_REQUEST: 'FAVORITE_MEDICATIONS_SWITCH_REQUEST',
    SWITCH_SUCCESS: 'FAVORITE_MEDICATIONS_SWITCH_SUCCESS',
    SWITCH_FAILURE: 'FAVORITE_MEDICATIONS_SWITCH_FAILURE'
};
