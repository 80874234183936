import { permissionConstants } from '../_constants';

export function permission(state = {permissions:[], }, action) {
  switch (action.type) {
    case permissionConstants.LOAD_REQUEST:
      return {
        permissions: [],
        loaded: false
      };
    case permissionConstants.LOAD_SUCCESS:
      return {
        permissions: action.permissions,
        loaded: true
      };
    case permissionConstants.DELETE_SUCCESS:
      return {
        permissions: state.permissions.filter(permission => permission.user_id !== action.id),
        loaded: true
      };
    default:
      return state
  }
}
