import { combineReducers } from 'redux';
import { condition } from './condition.reducer';
import { authentication } from './authentication.reducer';
import { favoriteMedications } from './favoriteMedications.reducer';
import { medicationQuestions } from './medicationQuestions.reducer';
import { index } from './index.reducer';
import { patients } from './patients.reducer';
import { admin } from './admin.reducer';
import { permission } from './permission.reducer';
import { subscription } from './subscription.reducer';
import { reducer as session } from 'redux-simple-auth'
import { toastsReducer as toasts } from 'react-toastify-redux';
import { loadingBarReducer } from 'react-redux-loading-bar'

const appReducer = combineReducers({
  condition,
  medicationQuestions,
  authentication,
  favoriteMedications,
  toasts,
  session,
  patients,
  index,
  admin,
  permission,
  subscription,
  loadingBar: loadingBarReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USERS_LOGOUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer;
