import {userActions} from '../_actions';
import {userConstants} from '../_constants';
import moment from "moment";
import jwtDecode from "jwt-decode";

export function jwt({ dispatch, getState }) {
    return (next) => (action) => {
        if (action.type !== userConstants.LOGOUT &&
          action.type !== userConstants.LOGIN_REQUEST &&
           action.type !== userConstants.LOGIN_SUCCESS &&
            action.type !== userConstants.LOGIN_FAILURE &&
             action.type !== userConstants.REFRESHING_TOKEN &&
              action.type !== userConstants.DONE_REFRESHING_TOKEN &&
                action.type !== "@@redux-simple-auth/AUTHENTICATE" &&
                  action.type !== "@@redux-simple-auth/AUTHENTICATE_FAILED" &&
                    action.type !== "@@redux-simple-auth/AUTHENTICATE_SUCCEEDED") {
          if (getState().session && getState().session.data && getState().session.data.user && getState().session.data.user.refresh_token ) {
              // decode jwt so that we know if and when it expires
              var tokenExpiration = jwtDecode(getState().session.data.user.authentication_token).exp;

              //var tokenExpiration = "";
              if (tokenExpiration && (moment(tokenExpiration) - moment(Date.now()/1000) < 1000)) {

                  // make sure we are not already refreshing the token

                  if (!getState().session.refreshing) {
                      var refreshTokenExpiration = jwtDecode(getState().session.data.user.refresh_token).exp;

                      if (refreshTokenExpiration && (moment(refreshTokenExpiration) - moment(Date.now()/1000) < 100)) {
                        userActions.logout()
                      }
                      else {
                        return userActions.refreshToken(dispatch).then(() => next(action));
                      }

                  } else {
                      return getState().authentication.freshTokenPromise.then(() => next(action));
                  }
              }
          }
        }
        return next(action);
    };
}
