import { patientConstants } from '../_constants';
import { patientService } from '../_services';
import { error as toastError} from 'react-toastify-redux';

export const patientActions = {
    getAll,
    getByPage,
    getCount,
    archive,
    refreshAll,
    'get': get,
    updateSearch,
    updatePagination,
    createNew,
    'update': update,
    reopen,
    searchArchived,
    'delete': _delete,
    syncPatientsPCC,
    syncPatientPCC
};


function getAll() {
    return dispatch => {

        dispatch(request());
        //console.log("REQUESTED")
        return patientService.getAll(1, 10, "")
            .then(
                patients => {
                    dispatch(success(patients.data))
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: patientConstants.GETALL_REQUEST } }
    function success(patients) { return { type: patientConstants.GETALL_SUCCESS, patients } }
    function failure(error) { return { type: patientConstants.GETALL_FAILURE, error } }
}

function getByPage(page, pageSize, query, sites) {
    return dispatch => {

        dispatch(request());
        console.log("GET BY PAGE")
        return patientService.getByPage(page, pageSize, query, sites)
            .then(
                patients => {
                    dispatch(success(patients.data))
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: patientConstants.GETALL_REQUEST } }
    function success(patients) { return { type: patientConstants.GETALL_SUCCESS, patients } }
    function failure(error) { return { type: patientConstants.GETALL_FAILURE, error } }
}

function syncPatientPCC(id)  {
    return dispatch => {
        dispatch(request(id));

        return patientService.syncPatientPCC(id)
            .then(
                patient => {
                    dispatch(success(patient));
                    return patient.data;
                },
                error => {
                    dispatch(failure(id, error));
                    dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: patientConstants.SYNC_REQUEST, id } }
    function success(patient) {
      return { type: patientConstants.SYNC_SUCCESS, patient: patient.data, id }
    }
    function failure(id, error) { return { type: patientConstants.SYNC_FAILURE, id, error } }
}


function syncPatientsPCC(site_id) {
    return dispatch => {

        dispatch(request(site_id));
        //console.log("REQUESTED")
        return patientService.syncPatientsPCC(site_id)
            .then(
                patients => {
                    dispatch(success(patients.data))
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: patientConstants.SYNC_REQUEST, site_id } }
    function success() { return { type: patientConstants.SYNC_SUCCESS } }
    function failure(error) { return { type: patientConstants.SYNC_FAILURE, error } }
}

function getCount() {
    return dispatch => {

        dispatch(request());
        return patientService.getCount()
            .then(
                results => {
                    dispatch(success(results.data))
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: patientConstants.GET_COUNT_REQUEST } }
    function success(count) { return { type: patientConstants.GET_COUNT_SUCCESS, count } }
    function failure(error) { return { type: patientConstants.GET_COUNT_FAILURE, error } }
}

function refreshAll(page, sizePerPage, searchQuery, filters) {
    return dispatch => {
        return patientService.getAll(page, sizePerPage, searchQuery, filters)
            .then(
                patients => dispatch(success(patients.data)),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error));
                }
            );
    };

    function success(patients) { return { type: patientConstants.GETALL_SUCCESS, patients } }
    function failure(error) { return { type: patientConstants.GETALL_FAILURE, error } }
}

function updateSearch(searchQuery) {
    return { type: patientConstants.UPDATE_SEARCH, searchQuery }
}

function updatePagination(page, sizePerPage) {
    return { type: patientConstants.UPDATE_PAGINATION, page, sizePerPage }
}

function get(id, site=null)  {
    return dispatch => {
        dispatch(request(id));
        if (site) {
            return patientService.get(id, site)
            .then(
                patient => {
                    dispatch(success(patient));
                },
                error => {
                    dispatch(failure(id, error));
                    dispatch(toastError(error));
                }
            );
        } else {
            return patientService.get(id)
            .then(
                patient => {
                    dispatch(success(patient));
                },
                error => {
                    dispatch(failure(id, error));
                    dispatch(toastError(error));
                }
            );
        }
        
    };

    function request(id) { return { type: patientConstants.GET_REQUEST, id } }
    function success(patient) {
      return { type: patientConstants.GET_SUCCESS, patient, id }
    }
    function failure(id, error) { return { type: patientConstants.GET_FAILURE, id, error } }
}

function reopen(id)  {
    return dispatch => {
        dispatch(request(id));

        return patientService.reopen(id)
            .then(
                patient => {
                    dispatch(success(patient));
                    return patient.data;
                },
                error => {
                    dispatch(failure(id, error));
                    dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: patientConstants.REOPEN_REQUEST, id } }
    function success(patient) {
      return { type: patientConstants.REOPEN_SUCCESS, patient: patient.data, id }
    }
    function failure(id, error) { return { type: patientConstants.REOPEN_FAILURE, id, error } }
}

function searchArchived(searchQuery)  {
    return dispatch => {
        dispatch(request(searchQuery));
        dispatch(success());
    };

    function request(searchFields) { return { type: patientConstants.SEARCH_ARCHIVED_REQUEST, searchQuery } }
    function success() {
      return { type: patientConstants.SEARCH_ARCHIVED_SUCCESS }
    }
    function failure(error) { return { type: patientConstants.SEARCH_ARCHIVED_FAILURE, error } }
}

function createNew(patient)  {
    return dispatch => {
        dispatch(request(patient));

        return patientService.create(patient)
            .then(
                () => {
                    dispatch(success(patient));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError("Unable to create this patient. Please verify that the patient code is valid and that the year of birth corresponds to that patient."));
                }
            );
    };

    function request(patient) { return { type: patientConstants.CREATE_REQUEST, patient } }
    function success(patient) { return { type: patientConstants.CREATE_SUCCESS, patient } }
    function failure(id, error) { return { type: patientConstants.CREATE_FAILURE, id, error } }
}

function update(values)  {
    return dispatch => {
        dispatch(request(values));

        return patientService.update(values)
            .then(
                patient => {
                    dispatch(success());

                    return patient;
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error));
                    return error;
                }
            );
    };

    function request(id) { return { type: patientConstants.UPDATE_REQUEST, values } }
    function success() { return { type: patientConstants.UPDATE_SUCCESS } }
    function failure(id, error) { return { type: patientConstants.UPDATE_FAILURE, id, error } }
}

function archive(values)  {
    return update({...values})
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        return patientService.delete(id)
            .then(
                user => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(id, error));
                    dispatch(toastError(error));
                }
            );
    };

    function request(id) { return { type: patientConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: patientConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: patientConstants.DELETE_FAILURE, id, error } }
}
