import { medicationConstants } from '../_constants';
import { medicationService } from '../_services';
import { error as toastError } from 'react-toastify-redux';

export const medicationActions = {
    get,
    createNew,
    update,
    'delete': _delete,
    loadQuestions,
    nextQuestion,
    previousQuestion,
    resetQuestion,
};

function update(patient_id, cellEdit) {
    return dispatch => {
        dispatch(request());
        return medicationService.update(patient_id, cellEdit)
            .then(
                response => dispatch(success(cellEdit)),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: medicationConstants.SAVE_REQUEST } }
    function success(cellEdit) { return { type: medicationConstants.SAVE_SUCCESS, cellEdit  } }
    function failure(error) { return { type: medicationConstants.SAVE_FAILURE, error } }
}

function createNew(patient, medication, isOutcome) {
    return dispatch => {
        dispatch(request());
        return medicationService.put(patient, medication, isOutcome)
            .then(
                medications => {
                    dispatch(get(patient))
                    dispatch(success())
                },
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: medicationConstants.CREATE_REQUEST } }
    function success() { return { type: medicationConstants.CREATE_SUCCESS } }
    function failure(error) { return { type: medicationConstants.CREATE_FAILURE, error } }
}

function get(patient) {
    return dispatch => {
        dispatch(request());
        return medicationService.get(patient)
            .then(
                medications => dispatch(success(medications)),
                error => {
                    dispatch(failure(error));
                    dispatch(toastError(error.message))
                }
            );
    };

    function request() { return { type: medicationConstants.GETALL_REQUEST } }
    function success(medications) { return { type: medicationConstants.GETALL_SUCCESS, medications: [...medications.data] } }
    function failure(error) { return { type: medicationConstants.GETALL_FAILURE, error } }
}

function _delete(id, patient_id) {
    return dispatch => {
        dispatch(request(id));

        return medicationService.delete(id)
            .then(
                user => {
                    dispatch(success(id))
                },
                error => {
                    dispatch(failure(id, error));
                }
            );
    };

    function request(id) { return { type: medicationConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: medicationConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: medicationConstants.DELETE_FAILURE, id, error } }
}

function loadQuestions(patient_id)  {
    return dispatch => {
        dispatch(request(patient_id));

        return medicationService.loadQuestions(patient_id)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(patient_id) { return { type: medicationConstants.LOAD_QUESTIONS_REQUEST, patient_id } }
    function success(questions) { return { type: medicationConstants.LOAD_QUESTIONS_SUCCESS, questions: questions.data } }
    function failure(id, error) { return { type: medicationConstants.LOAD_QUESTIONS_FAILURE, id, error } }
}

function nextQuestion() {
    return { type: medicationConstants.NEXT_QUESTION };
}

function previousQuestion() {
    return { type: medicationConstants.PREVIOUS_QUESTION };
}

function resetQuestion() {
    return { type: medicationConstants.RESET_QUESTION };
}