import axios from "axios";
import { authHeader, handleResponse } from '../_helpers';

export const subscriptionService = {
  subscribe,
  load,
  portal
};



function subscribe(customerId, paymentMethodId, product) {

  const config = {
    headers: { ...authHeader() }
  };

  const body = {
    customerId: customerId,
    paymentMethodId: paymentMethodId,
    product: product,
  }

  return axios.post("/create-subscription", body, config).then(
    response => {
      return response;
    });
}
function load() {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.get("/load-subscription", config).then(
    response => {
      return response;
    });
}

function portal() {

  const config = {
    headers: { ...authHeader() }
  };

  return axios.post("/create-customer-portal-session", {}, config).then(
    response => {
      return response.data;
    });
}