import { patientConstants, medicationConstants, conditionConstants, recommendationConstants } from '../_constants';

export function patients(state = {pagination: {totalSize: 10,sizePerPage: 10,page: 1}, filters:{displayArchived:false, displayedSites:{}}}, action) {
  switch (action.type) {
    case patientConstants.CREATE_SUCCESS:
      return {
        ...state,
        items: [
          ...state.items,
          action.patient
        ]
      };
    case patientConstants.GETALL_REQUEST:
      return {
        loading: true,
        filters: state.filters,
        pagination: state.pagination
      };
    case patientConstants.GETALL_SUCCESS:

      return {
        loading: false,
        filters:state.filters,
        items: action.patients.patients,
        pagination: {
            ...state.pagination,
            totalSize: action.patients.totalSize,
        }
      };
    case patientConstants.GET_COUNT_SUCCESS:
        return {
          ...state,
          count: action.count
        };
    case patientConstants.DELETE_SUCCESS:

        return {
          ...state,
          items: state.items.filter(patient => patient.patient_id !== action.id)
        };
    case patientConstants.GETALL_FAILURE:
      return {
        ...state,
        items: []
      };
    case patientConstants.GET_REQUEST:
      return {
        ...state,
        currentPatientId: action.id,
      };
    case patientConstants.GET_SUCCESS:
        return {
        ...state,
        currentPatient: action.patient.data,
      };
    case patientConstants.SYNC_SUCCESS:
        return {
        ...state,
        currentPatient: {...action.patient},
      };
    case patientConstants.UPDATE_REQUEST:
        return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          first_name: action.values.first_name,
          last_name: action.values.last_name,
          gender: action.values.gender,
          site: action.values.site,
          year_of_birth: action.values.year_of_birth,
          pps: action.values.pps,
          active: action.values.active,
          age: action.values.age ? action.values.age : state.currentPatient.age
        }
      };
    case patientConstants.REOPEN_SUCCESS:
        return {
        ...state,
        items: state.items && state.items.map(patient =>
          patient.patient_id === action.id
            ? { ...patient, active: true, reopened: true }
            : patient
        ),
        archivedPatients: state.archivedPatients && state.archivedPatients.map(patient =>
          patient.patient_id === action.id
            ? { ...patient, active: true, reopened: true }
            : patient
        )
      };
    case patientConstants.GET_FAILURE:
      return {
        ...state,
        currentPatient: {}
      };
    case conditionConstants.SAVE_REQUEST:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          conditions: action.values
        }
      };
    case medicationConstants.GETALL_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          medications: action.medications
        }
      };
    case recommendationConstants.GETALL_REQUEST:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          loadingRecommendations: true
        }
      };
    case recommendationConstants.GETALL_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          recommendations: [...action.recommendations.recommendations],
          loadingRecommendations: false
        }
      };
      case recommendationConstants.GETALL_FAILURE:
        return {
          ...state,
          currentPatient: {
            ...state.currentPatient,
            error: action.error,
            loadingRecommendations: false
          }
        };
    case recommendationConstants.DOWNLOAD_REPORT_REQUEST:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          loadingReport: true
        }
      };
    case recommendationConstants.DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          loadingReport: false
        }
      };
    case recommendationConstants.SEND_BY_EMAIL_REQUEST:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          sendingReport: true,
          sentReport: false
        }
      };
    case recommendationConstants.SEND_BY_EMAIL_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          sendingReport: false,
          sentReport: true
        }
      };
    case medicationConstants.DELETE_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          medications: state.currentPatient.medications.filter(medication => medication.medication_id !== action.id)
        }
      };
    case medicationConstants.SAVE_SUCCESS:
      return {
        ...state,
        currentPatient: {
          ...state.currentPatient,
          medications:
            state.currentPatient.medications.reverse().map( (med, index) => {
              if(med.medication_id === action.cellEdit.rowId){
                if(parseInt(med.stopped) !== 1 && !action.cellEdit.dataField.includes("outcome_")) {
                  return { ...med, [action.cellEdit.dataField]:action.cellEdit.newValue, ["outcome_"+action.cellEdit.dataField]:action.cellEdit.newValue }
                } else {
                  return { ...med, [action.cellEdit.dataField]:action.cellEdit.newValue }
                }
              }
              return med
            })
        }
      };
    case patientConstants.UPDATE_SEARCH:

      return {
        ...state,
        pagination: {
            ...state.pagination,
            searchQuery: action.searchQuery
        }
      };
    case patientConstants.UPDATE_PAGINATION:

      return {
        ...state,
        pagination: {
            ...state.pagination,
            page: action.page,
            sizePerPage: action.sizePerPage
        }
      };
    case patientConstants.SEARCH_ARCHIVED_REQUEST:

      return {
        ...state,
        archivedPatients: state.items && state.items.filter(patient => patient.first_name === action.searchQuery.first_name && patient.last_name === action.searchQuery.last_name && patient.year_of_birth == action.searchQuery.year_of_birth)
      };
    default:
      return state
  }
}
