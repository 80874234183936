import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";
import { error as toastError } from 'react-toastify-redux';
import { store } from '../_helpers';

export const favoriteMedicationsService = {
  delete: _delete,
  switch: _switch,
  add,
  getAll
};

function _switch(a,b) {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.post('/api/favorite_medications/'+a+'/'+b, {}, config).then(handleResponse);
}

function add(id) {

    const config = {
      headers: { ...authHeader() }
    };

    return axios.put('/api/favorite_medications/'+id, {}, config).then(handleResponse);
}

function getAll() {
    const config = {
      headers: { ...authHeader() }
    };

    return axios.get('/api/favorite_medications', config).then(handleResponse).catch(error => {store.dispatch(toastError("Failed to load favorite medications. Please try again later."));});
}

function _delete(id) {
    const requestOptions = {
        headers: authHeader()
    };

    return axios.delete('/api/favorite_medications/'+id, requestOptions).then(handleResponse);
}
