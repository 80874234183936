import React from 'react';
import { Router } from 'react-router-dom';
import { history } from '../_helpers';
import { store } from '../_helpers';
import '../css/custom-bootstrap.scss';
import '../css/App.css';
import 'react-toastify/dist/ReactToastify.css';
import { error as toastError } from 'react-toastify-redux';
import * as Sentry from '@sentry/browser';
import { App } from './App'
import { Button } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar'

Sentry.init({
  dsn: "https://f5161e5ad97049ecb87709aa3f5755d0@o4504163314630657.ingest.sentry.io/4504163622846464"
 });

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{textAlign:"center"}}>
          <br/>
          <h2>Something went wrong.</h2>
          <br/>
          <Button onClick={() => {window.location.reload()}}>Please click here to refresh the page</Button>
          <br/>
          <br/>
          <h2>If that doesn't work contact us at <a href="mailto:support@medsafer.org">support@medsafer.org</a></h2>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }  
}

class Container extends React.Component {

    constructor(props) {
      super(props)
      this.state = {}
      this.idleTimer = null
      this.onActive = this._onActive.bind(this)
      this.onIdle = this._onIdle.bind(this)
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
      // see if new content was found by the service worker
      if (nextProps.serviceWorker.serviceWorkerUpdated) {
        store.dispatch(toastError('The app has been updated. Refresh your browser to enjoy the latest version.'))
      }
    }

    render() {
        return (
          <div className="App" style={{height:"100%"}}>
            <ErrorBoundary>
              <Router history={history}>
                <React.Suspense fallback={<LoadingBar style={{ backgroundColor: '#4190BC'}}  updateTime={100} maxProgress={95} progressIncrease={10}  />}>
                  <App/>
                </React.Suspense>
              </Router>
            </ErrorBoundary>
          </div>
        );
    }

    _onActive(e) {
      console.log('user is active', e)
      console.log('time remaining', this.idleTimer.getRemainingTime())
    }

    _onIdle(e) {
      this.props.logout()
      window.location.reload(true);
      console.log('user is idle', e)
      console.log('last active', this.idleTimer.getLastActiveTime())
    }
}

export { Container };
