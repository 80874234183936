import { adminConstants } from '../_constants';

export function admin(state = {filesLoaded:false}, action) {
  switch (action.type) {
    case adminConstants.DOWNDLOAD_DPD_SUCCESS:
      return {
        ...state,
        filesLoaded:true
      }
    case adminConstants.GET_DRUGS_SUCCESS:
      return {
        ...state,
        new_drugs:action.drugs.data.new_drugs,
        updated_drugs:action.drugs.data.updated_drugs
      }
    default:
      return state
  }
}
