export const recommendationConstants = {
    GETALL_REQUEST: 'RECOMMENDATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'RECOMMENDATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'RECOMMENDATIONS_GETALL_FAILURE',
    DOWNLOAD_REPORT_REQUEST: 'RECOMMENDATIONS_DOWNLOAD_REPORT_REQUEST',
    DOWNLOAD_REPORT_SUCCESS: 'RECOMMENDATIONS_DOWNLOAD_REPORT_SUCCESS',
    DOWNLOAD_REPORT_FAILURE: 'RECOMMENDATIONS_DOWNLOAD_REPORT_FAILURE',
    SEND_BY_EMAIL_REQUEST: 'RECOMMENDATIONS_SEND_BY_EMAIL_REQUEST',
    SEND_BY_EMAIL_SUCCESS: 'RECOMMENDATIONS_SEND_BY_EMAIL_SUCCESS',
    SEND_BY_EMAIL_FAILURE: 'RECOMMENDATIONS_SEND_BY_EMAIL_FAILURE',
    GENERATE_BATCH_REPORT_REQUEST: 'RECOMMENDATIONS_GENERATE_BATCH_REPORT_REQUEST',
    GENERATE_BATCH_REPORT_SUCCESS: 'RECOMMENDATIONS_GENERATE_BATCH_REPORT_SUCCESS',
    GENERATE_BATCH_REPORT_FAILURE: 'RECOMMENDATIONS_GENERATE_BATCH_REPORT_FAILURE',
    DOWNLOAD_REPORTS_REQUEST: 'RECOMMENDATIONS_DOWNLOAD_REPORTS_REQUEST',
    DOWNLOAD_REPORTS_SUCCESS: 'RECOMMENDATIONS_DOWNLOAD_REPORTS_SUCCESS',
    DOWNLOAD_REPORTS_FAILURE: 'RECOMMENDATIONS_DOWNLOAD_REPORTS_FAILURE',
};
