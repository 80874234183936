import { medicationConstants } from '../_constants';

export function medicationQuestions(state = {questions:[], }, action) {
  switch (action.type) {
    case medicationConstants.LOAD_QUESTIONS_REQUEST:
      return {
        questions: [],
        loaded: false
      };
    case medicationConstants.LOAD_QUESTIONS_SUCCESS:
      return {
        questions: action.questions,
        displayed_question: 0,
        loaded: true
      };
    case medicationConstants.RESET_QUESTION:
      return {
        questions: state.questions,
        displayed_question: 0,
        loaded: false
      };
    case medicationConstants.NEXT_QUESTION:
      return {
        ...state,
        questions: state.questions,
        displayed_question: state.displayed_question+1
      };
    case medicationConstants.PREVIOUS_QUESTION:
      return {
        ...state,
        questions: state.questions,
        displayed_question: state.displayed_question-1
      };
    default:
      return state
  }
}
