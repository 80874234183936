import { store } from '../_helpers';
import { getSessionData } from 'redux-simple-auth'

export function authHeader() {
    // return authorization header with jwt token
    const sessionData = getSessionData(store.getState())
    if (sessionData && sessionData.user && sessionData.user.authentication_token) {
        return { 'Auth-Token': sessionData.user.authentication_token };
    }
    else {
        return { };
    }
}

export function refreshHeader() {
    // return authorization header with jwt token
    const sessionData = getSessionData(store.getState())
    if (sessionData && sessionData.user && sessionData.user.authentication_token) {
        return { 'Auth-Token': sessionData.user.refresh_token };
    } else {
        return {};
    }
}
