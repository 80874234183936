import { userService } from '../_services';

import {
    createAuthMiddleware,
    createAuthenticator
  } from 'redux-simple-auth'
  

const credentialsAuthenticator = createAuthenticator({
    'name': 'customAuthenticator',
    authenticate(data) {
      if ("authentication_token" in data) {
        return Promise.resolve().then(function () {
          localStorage.setItem('user', JSON.stringify(data));
          return {"user":data};
        });
      }
  
      return userService.login(data).then(payload => {
        localStorage.setItem('user', JSON.stringify(payload.data.response));
        try {
          payload.data.response.user.filters = JSON.parse(payload.data.response.user.filters).filters;
        }
        catch(err) {
          payload.data.response.user.filters = {displayedSites:[], displayArchived:false};
        }
        return payload.data.response;
      });
    },
    restore(data) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return Promise.resolve(user)
      }
      return Promise.reject()
    }
  })
  
  
  const pccAuthenticator = createAuthenticator({
    name: 'pccAuthenticator',
    authenticate(authenticationCode) {
  
      return userService.PCCAuth(authenticationCode)
      .then(
          payload => {
            localStorage.setItem('user', JSON.stringify(payload.data.response));
            try {
              payload.data.response.user.filters = JSON.parse(payload.data.response.user.filters).filters;
            }
            catch(err) {
              payload.data.response.user.filters = {displayedSites:[], displayArchived:false};
            }
            return payload.data.response;
          }
      ).catch(
        error => {
          return Promise.reject(error.response.data.error);
        }
      );
    },
    restore(data) {
      let user = JSON.parse(localStorage.getItem('user'));
      if (user) {
        return Promise.resolve(user)
      }
      return Promise.reject()
    }
  })
  
  const authMiddleware = createAuthMiddleware({ authenticators: [credentialsAuthenticator, pccAuthenticator] })
  
  export {authMiddleware}