/** Stripe publishable key */
const STRIPE_PUBLISHABLE_KEY = "pk_test_51HtFaRLAHyJItf0cHZQHx4WLn4dVPb03CMDJo89H9v3lQe1ETRNk8Huky67SMpMDts2xX0sKqZtQpoR49QlAo8j700EcL4dhfd";

export const subscriptionConstants = {
    LOAD_REQUEST: 'LOAD_REQUEST',
    LOAD_SUCCESS: 'LOAD_SUCCESS',
    LOAD_FAILURE: 'LOAD_FAILURE',
    CLEAR_PORTAL: 'CLEAR_PORTAL',
    PORTAL_REQUEST: 'PORTAL_REQUEST',
    PORTAL_SUCCESS: 'PORTAL_SUCCESS',
    PORTAL_FAILURE: 'PORTAL_FAILURE',
    SUBSCRIBE_REQUEST: 'SUBSCRIBE_REQUEST',
    SUBSCRIBE_SUCCESS: 'SUBSCRIBE_SUCCESS',
    SUBSCRIBE_FAILURE: 'SUBSCRIBE_FAILURE',
    UNSUBSCRIBE_REQUEST: 'UNSUBSCRIBE_REQUEST',
    UNSUBSCRIBE_SUCCESS: 'UNSUBSCRIBE_SUCCESS',
    UNSUBSCRIBE_FAILURE: 'UNSUBSCRIBE_FAILURE',
    STRIPE_PUBLISHABLE_KEY: STRIPE_PUBLISHABLE_KEY,
};
