import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify-redux';
import { withTranslation } from 'react-i18next';

const PrivateRoute = React.lazy(() => import('../_navigation/PrivateRoute'))
const LoginRoute = React.lazy(() => import('../_navigation/LoginRoute'))
const AnyRoute = React.lazy(() => import('../_navigation/AnyRoute'))

const PublicRoute = React.lazy(() => import('../_navigation/PublicRoute'))

const MainLayout = React.lazy(() => import('../Layouts/MainLayout'))
const WideMainLayout = React.lazy(() => import('../Layouts/WideMainLayout'))
const PatientLayout = React.lazy(() => import('../Layouts/PatientLayout'))
const IndexLayout = React.lazy(() => import('../Layouts/IndexLayout'))
const EmptyLayout = React.lazy(() => import('../Layouts/EmptyLayout'))

const Login = React.lazy(() => import('../AuthPages/Login'))
const Register = React.lazy(() => import('../AuthPages/Register'))
const Logout = React.lazy(() => import('../AuthPages/Logout'))
const ForgotPassword = React.lazy(() => import('../AuthPages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('../AuthPages/ResetPassword'))
const AccountSettings = React.lazy(() => import('../AuthPages/AccountSettings'))
const VerifyEmail = React.lazy(() => import('../AuthPages/VerifyEmail'))
const PCCAuth = React.lazy(() => import('../AuthPages/PCCAuth'))
const PrivacyStatement = React.lazy(() => import('../AuthPages/PrivacyStatement'))

const PatientsList = React.lazy(() => import('../Lists/PatientsList'))

/*const PublicMainForm = React.lazy(() => import('../PublicModules/MainForm'))
const DrugsUpdate = React.lazy(() => import('../Admin/DrugsUpdate'))

const PublicConditions = React.lazy(() => import('../PublicModules/Conditions'))
const PublicMedications = React.lazy(() => import('../PublicModules/Medications'))
const PublicRecommendations = React.lazy(() => import('../PublicModules/Recommendations'))
const Start = React.lazy(() => import('../PublicModules/Start'))
const PatientLogin = React.lazy(() => import('../PublicModules/PatientLogin'))
const MedicationQuestions = React.lazy(() => import('../PublicModules/MedicationQuestions'))
const Permission = React.lazy(() => import('../PublicModules/Permissions'))*/

const MainForm = React.lazy(() => import('../PatientPages/MainForm'))
const Conditions = React.lazy(() => import('../PatientPages/Conditions'))
const Medications = React.lazy(() => import('../PatientPages/Medications'))
const Recommendations = React.lazy(() => import('../PatientPages/Recommendations'))
const OutcomeMedications = React.lazy(() => import('../PatientPages/OutcomeMedications'))
const Reopen = React.lazy(() => import('../PatientPages/Reopen'))

require('dotenv').config()

class App extends React.Component {

    render() {
        return (
            <div style={{height: "100%"}}  data-spy="scroll" data-target="#probootstrap-navbar" data-offset="200">
              <Switch>
                <Route exact path="/" component={() => <Redirect to="/login" />}/>
                <LoginRoute path="/forgot_password" layout={IndexLayout} component={ForgotPassword} />
                <LoginRoute path="/reset_password/:passwordResetCode" layout={IndexLayout} component={ResetPassword} />
                <LoginRoute path="/verify_email/:verifyEmailCode" layout={EmptyLayout} component={VerifyEmail} />
                <LoginRoute path="/pcc/login" layout={EmptyLayout} component={PCCAuth} />

                <PrivateRoute path="/account_settings" layout={MainLayout} component={AccountSettings} />
                <LoginRoute path="/login" layout={IndexLayout} component={Login} />

                {/*<PublicRoute path="/public/conditions" layout={IndexLayout} component={PublicConditions} />
                <PublicRoute path="/public/medications" layout={IndexLayout} component={PublicMedications} />
                <PublicRoute path="/public/medication_questions" layout={IndexLayout} component={MedicationQuestions} />
                <LoginRoute path="/public/main_form" layout={IndexLayout} component={PublicMainForm} />
                <PublicRoute path="/public/recommendations" layout={IndexLayout} component={PublicRecommendations} />
                <LoginRoute path="/public/start" layout={IndexLayout} display_medsafer_info={true} component={Start} />
                <PublicRoute path="/public/permissions" layout={IndexLayout} component={Permission} />
                <LoginRoute path="/public/login" layout={IndexLayout} component={PatientLogin} />
                */}
                <AnyRoute path="/privacy-policy" layout={IndexLayout} component={PrivacyStatement} />
                <LoginRoute path="/register" layout={IndexLayout} component={Register} />
                <Route path="/logout" component={Logout} />
                <PrivateRoute path="/reopen/:patientId" layout={IndexLayout} component={Reopen} />
                <PrivateRoute path="/patients" layout={MainLayout} component={PatientsList} />
                <PrivateRoute path="/main_form/:patientId" layout={PatientLayout} component={MainForm} />
                <PrivateRoute path="/conditions/:patientId" layout={PatientLayout} component={Conditions} />
                <PrivateRoute path="/medications/:patientId" layout={PatientLayout} component={Medications} />
                <PrivateRoute path="/recommendations/:patientId" layout={PatientLayout} component={Recommendations} />
                <PrivateRoute path="/outcome_medications/:patientId" layout={PatientLayout} component={OutcomeMedications} />
                {/*<PrivateRoute path="/admin" layout={WideMainLayout} component={DrugsUpdate} />*/}

              </Switch>
              <ToastContainer />
            </div>
        );
    }
}

const trApp = withTranslation()(App);
export { trApp as App };
