import { permissionConstants } from '../_constants';
import { permissionService } from '../_services';

export const permissionActions = {
    loadPermissions,
    'delete': _delete
};

function _delete(id) {

    return dispatch => {
        dispatch(request(id));

        return permissionService.delete(id)
            .then(
                values => {
                    dispatch(success(id));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(values) { return { type: permissionConstants.DELETE_REQUEST, id } }
    function success(response) { return { type: permissionConstants.DELETE_SUCCESS, response } }
    function failure(id, error) { return { type: permissionConstants.DELETE_FAILURE, id, error } }

}

function loadPermissions()  {
    return dispatch => {
        dispatch(request());

        return permissionService.load()
            .then(
                permissions => {
                    dispatch(success(permissions.data));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request() { return { type: permissionConstants.LOAD_REQUEST } }
    function success(permissions) { return { type: permissionConstants.LOAD_SUCCESS, permissions } }
    function failure(id, error) { return { type: permissionConstants.LOAD_FAILURE, id, error } }
}