import { indexConstants } from '../_constants';

export function index(state = {news:[], mailSent:false}, action) {
  switch (action.type) {
    case indexConstants.LOAD_ALL_SUCCESS:
      return {
        news:action.news,
        mailSent:state.mailSent
      }
    case indexConstants.SEND_MAIL_SUCCESS:
      return {
        news:state.news,
        mailSent: true
      }
    default:
      return state
  }
}
