import { favoriteMedicationsConstants } from '../_constants';

export function favoriteMedications(state = [], action) {
  switch (action.type) {
    case favoriteMedicationsConstants.GETALL_SUCCESS:
      return action.favorite_medications.data;
    case favoriteMedicationsConstants.DELETE_SUCCESS:
      return state.filter(medication => medication.dpd_id !== action.response.dpd_id);
    case favoriteMedicationsConstants.SWITCH_REQUEST:
      return state.map(function(medication, index) {
        if (parseInt(medication.reorder_id) === parseInt(action.a)) return {...medication, reorder_id: action.b};
        else if (parseInt(medication.reorder_id) <= parseInt(action.b) && parseInt(medication.reorder_id) > parseInt(action.a)) return {...medication, reorder_id: parseInt(medication.reorder_id)-1};
        else if (parseInt(medication.reorder_id) >= parseInt(action.b) && parseInt(medication.reorder_id) < parseInt(action.a)) return {...medication, reorder_id: parseInt(medication.reorder_id)+1};
        else return medication;
    });
    case favoriteMedicationsConstants.ADD_SUCCESS:
      return [...state, action.response]
    default:
      return state
  }
}
