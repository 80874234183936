import { favoriteMedicationsConstants } from '../_constants';
import { favoriteMedicationsService } from '../_services';
import { error as toastError } from 'react-toastify-redux';

export const favoriteMedicationsActions = {
    delete: _delete,
    'add': add,
    getAll,
    switch: _switch
};

function add(id) {
    return dispatch => {
        dispatch(request(id));

        return favoriteMedicationsService.add(id)
            .then(
                response => {
                    dispatch(success(response.data))
                    
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request(id) { return { type: favoriteMedicationsConstants.ADD_REQUEST, id } }
    function success(response) { return { type: favoriteMedicationsConstants.ADD_SUCCESS, response } }
    function failure(error) { return { type: favoriteMedicationsConstants.ADD_FAILURE, error } }

}

function _switch(a, b) {
    return dispatch => {
        dispatch(request(a,b));
        return favoriteMedicationsService.switch(a,b)
            .then(
                response => {
                  dispatch(success(response.data));
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: favoriteMedicationsConstants.SWITCH_REQUEST, a, b } }
    function success(response) { return { type: favoriteMedicationsConstants.SWITCH_SUCCESS, response } }
    function failure(error) { return { type: favoriteMedicationsConstants.SWITCH_FAILURE, error } }

}

function _delete(id) {
    return dispatch => {
        dispatch(request(id));
        return favoriteMedicationsService.delete(id)
            .then(
                response => {
                  dispatch(success(response.data));
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request(id) { return { type: favoriteMedicationsConstants.DELETE_REQUEST, id } }
    function success(response) { return { type: favoriteMedicationsConstants.DELETE_SUCCESS, response } }
    function failure(error) { return { type: favoriteMedicationsConstants.DELETE_FAILURE, error } }

}

function getAll() {
    return dispatch => {

        dispatch(request());
        return favoriteMedicationsService.getAll()
            .then(
                favorite_medications => {
                    dispatch(success(favorite_medications))
                }
            ).catch(
                error => {
                    dispatch(failure(error.error));
                    dispatch(toastError(error));
            });
    };

    function request() { return { type: favoriteMedicationsConstants.GETALL_REQUEST } }
    function success(favorite_medications) { return { type: favoriteMedicationsConstants.GETALL_SUCCESS, favorite_medications } }
    function failure(error) { return { type: favoriteMedicationsConstants.GETALL_FAILURE, error } }
}
