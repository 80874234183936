import { authHeader, handleResponse } from '../_helpers';
import axios from "axios";

export const permissionService = {
    load,
    delete: _delete
};

function load(patient_id) {

  const config = {
    headers: { ...authHeader() }
  };
  return axios.get('/api/permissions', config).then(handleResponse);
}


function _delete(id) {

    const config = {
      headers: { ...authHeader() }
    };
    return axios.delete('/api/permissions/' + id, config).then(handleResponse);
}
